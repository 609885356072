@font-face {
  font-family: Futura New;
  src: url("FuturaNewLight-Reg.c3e4e517.eot");
  src: local(Futura New Light Reg), local(FuturaNewLight-Reg), url("FuturaNewLight-Reg.c3e4e517.eot#iefix") format("embedded-opentype"), url("FuturaNewLight-Reg.9d1d45c1.woff2") format("woff2"), url("FuturaNewLight-Reg.edbe4070.woff") format("woff"), url("FuturaNewLight-Reg.9526e345.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Futura New;
  src: url("FuturaNewBold-Reg.804ccc84.eot");
  src: local(Futura New Bold Reg), local(FuturaNewBold-Reg), url("FuturaNewBold-Reg.804ccc84.eot#iefix") format("embedded-opentype"), url("FuturaNewBold-Reg.7b608f5d.woff2") format("woff2"), url("FuturaNewBold-Reg.cd994ed8.woff") format("woff"), url("FuturaNewBold-Reg.ce513915.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Futura New;
  src: url("FuturaNewBook-Reg.08ed4795.eot");
  src: local(Futura New Book Reg), local(FuturaNewBook-Reg), url("FuturaNewBook-Reg.08ed4795.eot#iefix") format("embedded-opentype"), url("FuturaNewBook-Reg.24b9e46b.woff2") format("woff2"), url("FuturaNewBook-Reg.b7171b3b.woff") format("woff"), url("FuturaNewBook-Reg.f5a339aa.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  color: #000;
  background-color: #f5f9fe;
  font-family: Futura New;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}

.container {
  width: 96%;
  margin: 0 auto;
  padding: 0 6px;
}

.main {
  grid-template: "head" min-content
                 "orders" 1fr
                 "complete" min-content
                 / 1fr;
  min-height: 100vh;
  display: grid;
}

.header {
  grid-area: head;
}

.orders {
  grid-area: orders;
}

.complete-orders {
  grid-area: complete;
}

.ninja-logo {
  opacity: .6;
}

.header {
  z-index: 20;
  background-color: #fff;
  border-bottom: 1px solid #60d6a9;
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: 0 5px 10px 2px #aee8bf33;
}

.header-container {
  text-align: center;
  margin: 8px auto;
  padding: 10px;
  position: relative;
}

.corner__title {
  color: #1f271b;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  font-size: 20px;
  font-weight: normal;
  line-height: 22px;
  display: flex;
  position: relative;
}

.corner-name {
  color: #1f271b;
  font-family: Futura New;
  font-size: 20px;
  font-weight: normal;
  line-height: 1;
}

.icon-login {
  margin-top: 5px;
  position: absolute;
  right: 5px;
}

.orders__tabs {
  cursor: pointer;
  background-color: #fff;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
  display: flex;
}

.buttons__wrapper {
  justify-content: flex-end;
  display: flex;
}

.tabs-button {
  cursor: pointer;
  background: #fff;
  border: 1px solid #0000001a;
  border-radius: 8px;
  align-items: center;
  margin-left: 4px;
  padding: 6px;
  font-size: 12px;
  display: flex;
}

.button-container {
  justify-content: flex-start;
  max-width: 207px;
  margin-right: 16px;
  display: flex;
}

.button--active {
  background-color: #f5f9fe;
}

.tabs-button__text {
  font-size: 12px;
  line-height: 1.4;
}

.button--active .tabs-button__text {
  font-weight: bold;
}

.tabs-button__text--inactive {
  color: #97a091;
  font-size: 12px;
  line-height: 1.4;
}

.tabs-button__counter {
  text-align: center;
  color: #fff;
  background-color: #039be5;
  border-radius: 24px;
  margin-left: 4px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 1.4;
}

.orders {
  overflow: visible;
}

.orders__list {
  z-index: 10;
  background-color: #f5f9fe;
  border-top: 1px solid #e5e5e5;
  padding-top: 120px;
  position: relative;
}

.orders__card {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 8px;
  display: flex;
  position: relative;
}

.orders__card-left {
  justify-content: start;
  align-items: center;
  display: flex;
}

.orders__card-right {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.order__pack {
  display: block;
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.card__icon {
  margin-right: 9px;
}

.card__info {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.info__number {
  font-size: 18px;
  line-height: 1.4;
}

.info__contact {
  color: #19647e;
  opacity: .34;
  margin-bottom: 5px;
  font-size: 10px;
  line-height: 1.4;
}

.info__contact a {
  color: #19647e;
  font-size: 10px;
  line-height: 1.4;
  text-decoration: none;
}

.info__price {
  color: #28afb0;
  font-size: 14px;
  line-height: 1.4;
}

.order__properties {
  justify-content: flex-end;
  display: flex;
}

.order__comment {
  justify-content: flex-end;
  align-items: start;
  display: flex;
}

.comment-icon--hidden {
  display: none;
}

.order__status {
  color: #fff;
  border-radius: 8px;
  margin-left: 4px;
  padding: 2px 8px;
  font-size: 12px;
  line-height: 1.4;
}

.order__status--new {
  background-color: #039be5;
}

.order__status--timer {
  background-color: #60d6a9;
}

.order__status--processing {
  background-color: #ffa640;
}

.order__status--complete, .order__status--finished {
  background-color: #00bc29;
}

.order__status--canceled {
  background-color: #afafaf;
}

.order__pack {
  font-size: 14px;
  line-height: 1.4;
}

.order__item {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin-bottom: 80px;
  padding: 8px;
}

.order {
  max-width: 376px;
  margin: 10px auto;
  padding: 10px;
}

.order__row {
  border-bottom: 1px dashed #0003;
  justify-content: space-between;
  display: flex;
}

.order__row + .order__row {
  margin-top: 4px;
}

.order__col-name {
  color: #0f4152;
  font-size: 16px;
  font-weight: 400;
}

.order__col-value {
  color: #19647e;
  text-align: right;
  font-size: 16px;
}

.order__client {
  color: #19647e;
}

.order__client a {
  color: #19647e;
  margin-left: 5px;
  text-decoration: none;
}

.order__button-wrap {
  background: #f5f9fe;
  flex-direction: row;
  padding: 8px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 20px #3143522e;
}

.order__button {
  color: #19647e66;
  background: #1f1d1b1a;
  border: 0;
  border-radius: 8px;
  flex-direction: row;
  flex: 47%;
  justify-content: center;
  width: 100%;
  max-width: 359px;
  height: 56px;
  margin: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 56px;
  display: flex;
  box-shadow: -1px 3px 10px #0000000f;
}

.order__button svg {
  display: none;
}

.order__button_accept {
  background: none;
  border-radius: 8px;
  align-items: center;
  transition: all .5s;
}

.order__button_accept svg {
  fill: #000;
  margin-right: 12px;
  display: block;
}

.order__button_deny {
  color: #c85f35;
  background: none;
  border: 2px solid #c85f35;
  border-radius: 8px;
  align-items: center;
}

.order__button_deny svg {
  fill: #fff;
  margin-right: 12px;
  display: block;
}

.order__title {
  color: #1f271b;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin-bottom: 10px;
  padding: 4px;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.order__title svg {
  fill: #1f271b;
  stroke: none;
  stroke-width: 2px;
  margin-top: 4px;
  margin-left: 8px;
}

.order__table {
  max-width: 100%;
  margin: 50px auto auto;
}

.buttons_block {
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.order__button_accept--active, .order__button_deny--active {
  flex-basis: 80%;
}

.deny__form {
  transition: 5;
  z-index: 1000;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: 15px;
  max-width: 280px;
  margin: 0 auto;
  padding-top: 16px;
  font-size: 16px;
  line-height: 1.4;
  position: absolute;
  bottom: 50%;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 20px #314352;
}

.deny_text {
  padding-bottom: 16px;
}

.deny_buttons {
  display: flex;
  overflow: hidden;
}

.btn_deny {
  background-color: #fff;
  border: none;
  border-top: 1px solid #afafaf;
  border-bottom-left-radius: 15px;
  flex-basis: 50%;
  padding: 10px 0;
}

.btn_accept {
  background-color: #fff;
  border: none;
  border-top: 1px solid #afafaf;
  border-left: 1px solid #afafaf;
  border-bottom-right-radius: 15px;
  flex-basis: 50%;
  padding: 10px 0;
}

.status {
  text-align: center;
  margin: 4px 10px;
  padding: 4px 10px;
  font-size: 20px;
  font-weight: 600;
}

.order__status--waiting {
  color: #039be5;
}

.order__status--confirmed {
  color: #60d6a9;
}

.order__status--refused, .order__status--cancelled {
  color: #28afb0;
}

.order__status--expects {
  color: #ffa640;
}

.order__status--cooking {
  color: #c85f35;
}

.order__status--ready, .order__status--waiting_on_counter {
  color: #00bc29;
}

.order__status--issued_courier {
  color: #60d6a9;
}

.order__status--received {
  color: #03751c;
}

.btn--waiting {
  color: #fff;
  background-color: #00bc29;
}

.btn--confirmed {
  color: #fff;
  background-color: #60d6a9;
}

.btn--refused {
  color: #fff;
  background-color: #28afb0;
}

.btn--expects {
  color: #fff;
  background-color: #ffa640;
}

.btn--cooking {
  color: #fff;
  background-color: #26d320;
}

.btn--ready, .btn--waiting_on_counter {
  color: #fff;
  background-color: #00bc29;
}

.btn--issued_courier {
  color: #fff;
  background-color: #1f271b;
}

.btn--received {
  color: #fff;
  background-color: #03751c;
}

.order__date {
  text-align: center;
  color: #60d6a9;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
  display: flex;
}

.order__position {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.order__weight {
  color: #0009;
  font-size: 12px;
}

.complete {
  z-index: 19;
  background: linear-gradient(270.17deg, #006a97 -4.85%, #d4ff9d 118.36%), linear-gradient(180.09deg, #006a97 9.65%, #d4ff9d 125.55%), #121515;
  border-top: 1px solid #e5e5e5;
  width: 100%;
  padding: 8px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
}

.complete-orders {
  flex-direction: row;
  display: flex;
}

.orders__card--complete {
  flex-shrink: 0;
  min-width: 260px;
  margin-right: 8px;
}

.success__splash {
  transition: 5;
  z-index: 1000;
  text-align: center;
  color: #fff;
  background-color: #00bc29;
  border-radius: 15px;
  max-width: 70%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 30px;
  position: absolute;
  bottom: 90px;
  left: 0;
  right: 0;
}

.splash {
  align-items: bottom;
}

.switch {
  width: 38px;
  height: 20px;
  margin-left: 20px;
  display: inline-block;
  position: relative;
}

.switch input {
  display: none;
}

.slider {
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #19647e;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  background-color: #ee534b;
  width: 12px;
  height: 12px;
  transition: all .4s;
  position: absolute;
  bottom: 2px;
  left: 2px;
}

input:checked + .slider {
  background-color: #28afb0;
}

input:focus + .slider:before, input:checked + .slider:before {
  background-color: #f5f9fe;
}

input:focus + .slider {
  box-shadow: 0 0 1px #28afb0;
}

input:checked + .slider:before {
  transform: translateX(18px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.categories {
  background-color: #f5f9fe;
  padding-top: 114px;
}

.categories__stop {
  justify-content: space-between;
  padding: 16px 8px 16px 12px;
  display: flex;
}

.stop__text {
  font-size: 16px;
  line-height: 1.4;
}

.categories__list {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 8px 0;
  display: flex;
}

.categories__card {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 8px;
  display: flex;
}

.categories__content {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.categories__burger {
  object-fit: cover;
  align-items: center;
  width: 24px;
  height: 24px;
}

.categories__image {
  object-fit: cover;
  width: 52px;
  height: 52px;
  margin-left: 4px;
  margin-right: 8px;
}

.categories__image img {
  width: 40px;
  height: 40px;
  margin: 6px;
}

.categories__name {
  font-size: 20px;
  line-height: 1.4;
}

.categories__name--inactive {
  opacity: .2;
}

.categories__nav {
  object-fit: cover;
  width: 8px;
  height: 14px;
}

.outlet__nan {
  color: red;
}

.outlets__title {
  color: #fff;
  text-align: center;
  margin: auto;
  font-size: 30px;
  font-weight: bold;
}

.categorie__back {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.categorie__title {
  place-content: center;
  width: 100%;
  display: flex;
}

.categorie__stop {
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.categorie__control {
  margin-top: 20px;
}

.categorie__name {
  font-size: 20px;
  line-height: 1.4;
}

.categorie__wrapper {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.categorie__card {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  flex-direction: column;
  min-width: 90%;
  margin-bottom: 8px;
  margin-left: 8px;
  padding: 14px 8px;
  display: flex;
}

.categorie__items {
  margin-bottom: 20px;
}

.card__container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.card__name {
  max-width: 144px;
  font-size: 14px;
  line-height: 1.1;
}

.card__information {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.card__options {
  border-top: 1px dashed #e5e5e5;
  margin-top: 8px;
}

.options__title {
  color: #19647e;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  font-size: 12px;
  line-height: 1.1;
}

.card__option-item {
  border-top: 1px dashed #e5e5e5;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding: 8px 0;
  display: flex;
}

.option__name {
  color: #19647e;
  max-width: 70%;
  font-size: 12px;
}

.card__option-item--inactive > .option__name, .card__option-item--inactive > .card__information > .card__price, .categorie__card--inactive > .card__container > .card__name, .categorie__card--inactive > .card__container > .card__information > .card__price {
  opacity: .4;
}

.card__weight {
  color: #19647e;
  font-size: 10px;
}

.card__product {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.card__product img, .card__img {
  border-radius: 50%;
  margin: auto;
}

.card__name {
  margin-left: 4px;
}

.page__container {
  flex: 1 0 auto;
}

.page__content {
  height: 100%;
}

.main__container {
  box-sizing: border-box;
  min-width: 320px;
  margin-top: 111px;
  padding: 0 16px;
}

@media (width >= 768px) {
  .main__container {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}

@media (width >= 1200px) {
  .main__container {
    width: 1144px;
  }
}

@media (width >= 1920px) {
  .main__container {
    width: 1530px;
  }
}

.main__content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.main__logo {
  width: 125px;
  height: 108px;
  margin-bottom: 13px;
}

.main__logo img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.input {
  flex-direction: column;
  width: 193px;
  margin-bottom: 16px;
  display: flex;
  position: relative;
}

.input__input {
  resize: none;
  color: #000;
  appearance: none;
  box-sizing: border-box;
  background: #f5f9fe;
  border: 1px solid #28afb0;
  border-radius: 8px;
  outline: none;
  height: 36px;
  padding: 0 8px;
  font-size: 16px;
  line-height: 36px;
}

.input__input::-moz-placeholder {
  color: #1f271b;
  opacity: .2;
}

.input__input:-ms-input-placeholder {
  color: #1f271b;
  opacity: .2;
}

.input__input::placeholder {
  color: #1f271b;
  opacity: .2;
}

.input__input::-ms-clear {
  display: none;
}

.input__input_error {
  border-color: red;
}

.input_error-text {
  display: none;
}

.input_error .input__input {
  border-color: red;
}

.input_error .input__label {
  display: flex;
}

.main__button {
  color: #fff;
  background: #2baa99;
  border: 0;
  border-radius: 8px;
  width: 193px;
  height: 41px;
  font-size: 16px;
  font-weight: 500;
  line-height: 41px;
  box-shadow: -1px 3px 10px #0000000f;
}

.main__logo-text {
  text-align: center;
  width: 134px;
  height: 25px;
  margin-bottom: 6px;
}

.popup-page__content {
  z-index: 2000;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-page__content a {
  color: #19647e;
  margin-left: 5px;
  text-decoration: none;
}

.profile__data {
  color: #19647e;
  margin-left: 5px;
  font-size: 16px;
  text-decoration: none;
}

.market__label {
  color: #000;
  margin-left: 5px;
  text-decoration: none;
}

.profile-page__body {
  box-sizing: border-box;
  text-align: center;
  background: #f6fafbd0;
  border-radius: 16px;
  align-items: center;
  width: 240px;
  padding: 44px 4px;
  position: relative;
}

.profile__img {
  position: absolute;
  top: -65px;
  left: 70px;
}

.profile__img img {
  border-radius: 50px;
  box-shadow: -1px 3px 10px #0000000f;
}

.corner__name {
  color: #000;
  width: 90%;
  margin: 4px auto;
  font-size: 24px;
  line-height: 34px;
}

.popup-page__logout {
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin: 10px auto;
}

.overlay {
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay_active {
  opacity: 1;
  visibility: visible;
}

.overlay-success {
  opacity: 0;
  visibility: hidden;
  background-color: linear-gradient(270.17deg, #006a97 -4.85%, #d4ff9d 118.36%), linear-gradient(180.09deg, #006a97 9.65%, #d4ff9d 125.55%);
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.new_order {
  z-index: 1001;
  text-align: center;
  background-color: #0ab89b4d;
  border-radius: 12px;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 20%;
  left: 30px;
  right: 30px;
  box-shadow: -1px 3px 10px #0000000f;
}

.new_order_num {
  color: #fff;
  font-size: 80px;
  font-weight: 600;
}

.new_order_text {
  color: #fff;
  margin-top: 14px;
  padding: 10px auto;
  font-size: 16px;
  font-weight: 300;
}

.ocean {
  background: #015871;
  width: 100%;
  height: 5%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.wave {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg") repeat-x;
  width: 6400px;
  height: 198px;
  animation: 7s cubic-bezier(.36, .45, .63, .53) infinite wave;
  position: absolute;
  top: -198px;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  opacity: 1;
  animation: 7s cubic-bezier(.36, .45, .63, .53) -.125s infinite wave, 7s -1.25s infinite swell;
  top: -175px;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0, -25px, 0);
  }

  50% {
    transform: translate3d(0, 5px, 0);
  }
}

.loader {
  justify-content: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.fond {
  margin: auto;
}

.container_general {
  -ms-animation: animball_two 1s infinite;
  width: 44px;
  height: 44px;
  animation: 1s infinite animball_two;
}

.container_mixt {
  width: 44px;
  height: 44px;
  position: absolute;
}

.ballcolor {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.ball_1, .ball_2, .ball_3, .ball_4 {
  -ms-animation: animball_one 1s infinite ease;
  animation: 1s infinite animball_one;
  position: absolute;
}

.ball_1 {
  background-color: #cb2025;
  top: 0;
  left: 0;
}

.ball_2 {
  background-color: #f8b334;
  top: 0;
  left: 24px;
}

.ball_3 {
  background-color: #00a096;
  top: 24px;
  left: 0;
}

.ball_4 {
  background-color: #97bf0d;
  top: 24px;
  left: 24px;
}

@keyframes animball_one {
  0% {
    position: absolute;
  }

  50% {
    opacity: .5;
    position: absolute;
    top: 12px;
    left: 12px;
  }

  100% {
    position: absolute;
  }
}

@-webkit-keyframes animball_two {
  0% {
    -webkit-transform: rotate(0)scale(1);
  }

  50% {
    -webkit-transform: rotate(360deg)scale(1.3);
  }

  100% {
    -webkit-transform: rotate(720deg)scale(1);
  }
}

@-moz-keyframes animball_two {
  0% {
    -moz-transform: rotate(0)scale(1);
  }

  50% {
    -moz-transform: rotate(360deg)scale(1.3);
  }

  100% {
    -moz-transform: rotate(720deg)scale(1);
  }
}

@keyframes animball_two {
  0% {
    transform: rotate(0)scale(1);
  }

  50% {
    transform: rotate(360deg)scale(1.3);
  }

  100% {
    transform: rotate(720deg)scale(1);
  }
}
/*# sourceMappingURL=index.9d834310.css.map */
